body {
  font-family: 'Montserrat', sans-serif;
}

.centeredLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.centeredLogo img {
  max-width: 100%;
  max-height: 100%;
}

.textBelowSVG h1 {

  color: white !important;
  font-style: normal;
  text-align: center;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 25px;
}

.confirmBtn button {
  background: url('../../../assets/images/buttonBackground.png'), no-repeat;
  color: white;
  height: 43px;
  width: 100%;
  border: none !important;
  margin-bottom: 20px;
  text-decoration: none;
  font-weight: bold;
  margin-top: 10px;
}

.confirmBtn,
.cancelBtn {
  width: 100%;
}


.cancelBtn button {
  background: #000000;
  width: 100%;
  height: 43px;
  border-radius: 16px;
  border: 2px solid #5D3A96 !important;
  color: white;
  margin-bottom: 20px;

  font-weight: bold;
}

.customModal {
  text-align: center;

  .ant-modal-body {
    background: black !important;
    height: 350px !important;
    justify-content: center;
    align-items: center;

    display: flex;
    flex-direction: column;
    color: white !important;
  }

  .ant-modal-header {

    border-bottom: 1px solid #000000 !important;
  }

  .ant-modal-content {
    color: white;
    width: 100% !important;
    border-radius: 10px !important;
    overflow: hidden;
  }

}

.pausedPermanently p {
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}