.card-warpper {
    background: linear-gradient(281.62deg, #37225A -1.26%, #1F1333 49.37%, #321F53 100%) !important;
    border: 1px solid rgb(103, 34, 103, 0.2) !important;
    border-radius: 16px !important;
    box-shadow: inset 3px 4px 4px 0px rgba(255, 249, 249, 0.25), 8px 8px 20px 0px rgba(243, 233, 251, 0.3);
    width: 230px;
    height: 217px;
    cursor: pointer;
}

.card-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
}

.card-icon {
    color: #fff !important;
    font-size: larger;
    font-weight: lighter !important;
}

.card-heading {
    color: #fff !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    // max-width: 100px !important;
    line-height: 20px;
    text-align: left !important;
}

.card-analytics-number {
    color: #fff !important;
    font-weight: bold !important;
    font-size: 32px;
    line-height: 32px;
    margin-top: .5rem;
    text-align: center !important;
}

.card-divider {
    height: 3px !important;
    background-color: #fff !important;
}

.ant-progress-outer {
    height: 16px !important;
    top: 27px !important;
    border-radius: 25px !important;
}

.ant-progress-bg {
    background-color: rgba(173, 112, 224, 1) !important;
    height: 16px !important;
}

.ant-progress-text {
    color: #fff !important;
}

.line {
    height: 10px;
    margin-top: .2rem;
    border-left: 1px solid rgba(173, 112, 224, 1);
    color: #AD70E0;
    font-Family: 'Montserrat';
    position: absolute;
}

.progressTitle {
    font-family: 'Montserrat', sans-serif;
    font-size: 11px;
    font-style: italic;
    font-weight: 400;
    letter-spacing: 0;
    color: rgba(255, 255, 255, 1);
    margin-top: 0.5rem;
    text-align: left;
}

.progressPercent {
    color: #AD70E0;
    font-Family: 'Montserrat';
    position: absolute;
    font-size: 11px;
    line-height: 1rem;

}


// .progressPercent::after {
//     content: '|';
//     display: flex;
//     flex-direction: column;
//     gap: 0px
// }