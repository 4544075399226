.homeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../../../assets//images/background.png");
  background-position-y: bottom;
  height: 100%;
  width: 100%;
}
.homePoweredText {
  height: 170px;
}
#analytics {
  cursor: not-allowed;
}
.poweredText {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: end;
  color: #00c2cb;
  display: block;
  padding-top: 30px;
}
.homeInnerContainer {
  width: 80%;
  margin: auto;
}
.homePageOuterContainer {
  border: 2px solid #00c2cb;
  border-radius: 10px;
  height: 570px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card1 {
  background-image: url(../../../assets//images/card.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 360px;
  width: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.homepageHeading {
  background: #0a0a0a;
  position: absolute;
  width: 26%;
  top: -5%;
  left: 4%;
}
.homepageHeading h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  color: #5ce1e6;
  background: transparent;
}
.institution {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #0a0a0a;
}
.cardStyling {
  height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.imgContainer img {
  width: 38%;
}
.imgContainerClub img {
  width: 90px;
}
@media (max-width: 1600px) {
  .homePageOuterContainer {
    height: 450px;
  }
  .homepageHeading h1 {
    font-size: 30px;
  }
  .card1 {
    height: 275px;
    width: 200px;
  }
  .institution {
    font-size: 18px;
    padding-top: 10px;
  }
  .instImg img {
    width: 65%;
  }
  .imgContainerClub img {
    width: 64px;
    height: 64px;
  }
}
@media (max-width: 1200px) {
  .card1 {
    height: 220px;
    width: 160px;
  }
  .institution {
    padding-top: 0px;
  }
  .institution {
    line-height: 28px;
    padding-top: 20px;
  }
  .cardStyling {
    height: 120px;
  }
  .imgContainer img {
    width: 35%;
  }
  .instImg img {
    width: 55%;
  }
  .imgContainerClub img {
    width: 50px;
  }
}
@media (max-width: 992px) {
  .homepageHeading h1 {
    font-size: 16px;
    line-height: 33px;
  }
  .poweredText {
    font-size: 16px;
    justify-content: center;
  }
  .card1 {
    height: 155px;
    width: 110px;
  }
  .institution {
    font-size: 14px;
    line-height: 18px;
  }
  .homepageHeading {
    width: 20%;
  }
  .imgContainer img {
    width: 30%;
  }
  .instImg img {
    width: 30%;
  }
  .cardStyling {
    height: 60px;
  }
  .imgContainerClub img {
    width: 35px;
  }
  .imgContainerClub {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    height: 33px;
    flex-direction: column;
  }
}
