.institutionContainer {
  background-color: #0a0a0a;
  height: 100%;
}
.institutionInnerContainer {
  width: 80%;
  height: 80%;
  margin: auto;
}
.institutionPoweredText {
  height: 170px;
}
.arrowStyling {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}
.institutionNLBtnStyling {
  padding-top: 22px;
  display: flex;
  justify-content: end;
  align-items: center;
}
.institutionNLBtnStyling button.ant-btn.ant-btn-primary.NLBtn {
  height: 60px !important;
  width: 175px !important;
  font-family: "Helvetica" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 23px !important;
  background-color: #00c2cb !important;
  color: #0a0a0a !important;
  border: 1px solid #00c2cb !important;
}
.institutionPageOuterContainer {
  border: 2px solid #00c2cb;
  border-radius: 10px;
  height: 650px;
  margin: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.instpageHeading {
  background: #0a0a0a;
  position: absolute;
  width: 26%;
  top: -5%;
  left: 4%;
}
.instpageHeading h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  color: #5ce1e6;
  background: transparent;
}
.institutioncard1 {
  background-image: url(../../../assets//images/buttonbg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 200px;
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.institutioncardInner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.institutioncardInner button.ant-btn.ant-btn-primary.NLBtn {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 32px !important;
  line-height: 44px !important;
  text-align: center !important;
  color: #0a0a0a !important;
}
.institutioncustColStyling .ant-col.ant-col-8 {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.instinstitution {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #0a0a0a;
  overflow: hidden;
  max-width: 130px;
  margin-left: 15px;
  margin-right: 15px;
  text-overflow: ellipsis;
}
.institutionpoweredText {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: end;
  color: #00c2cb;
  display: block;
}
.institutionuniversitiesScroll {
  width: 95%;
  margin: auto;
  height: 74%;
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-row {
  align-items: center !important;
}
span.anticon.anticon-arrow-left {
  color: #5ce1e6;
  font-size: 30px;
}
.institutionuniversitiesScroll .ant-col.ant-col-6 {
  display: flex;
  justify-content: center;
}
.ant-modal-header {
  background-color: #000000 !important;
}
span.anticon.anticon-close.ant-modal-close-icon {
  color: black;
  font-size: 20px;
}
.ant-modal-content {
  border-radius: 10px !important;
}
.ant-modal-header {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}
.ant-modal-title {
  font-size: 20px !important;
  font-weight: bold !important;
}
.modalInputStyling .ant-col.ant-form-item-label {
  height: 30px;
}
.scrollContent .ant-col.ant-col-md-8.ant-col-lg-6 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalInputStyling .ant-form-item {
  margin-bottom: 12px;
}
@media (max-width: 1600px) {
  .instinstitution {
    font-size: 14px;
    line-height: 25px;
  }
  .institutionPoweredText {
    height: 170px;
  }
  .institutionPageOuterContainer {
    height: 460px;
  }
  .instpageHeading h1 {
    font-size: 30px;
  }
  .institutioncard1 {
    width: 180px;
    height: 170px;
  }
  .institutionpoweredText {
    font-size: 18px;
  }
  .institutionuniversitiesScroll {
    height: 90%;
  }
}
@media (max-width: 1400px) {
  .institutioncard1 {
    width: 170px;
    height: 160px;
  }
  .institutionpoweredText {
    font-size: 16px;
  }
  .institutionNLBtnStyling button.ant-btn.ant-btn-primary.NLBtn {
    font-size: 18px !important;
  }
  .instinstitution {
    font-size: 12px;
    line-height: 20px;
  }
  .institutionuniversitiesScroll {
    height: 84%;
  }
  form.ant-form.ant-form-horizontal {
    height: 300px;
  }
}
@media (max-width: 1200px) {
  .institutionNLBtnStyling button.ant-btn.ant-btn-primary.NLBtn {
    height: 65px !important;
    width: 165px !important;
  }
  .institutioncard1 {
    width: 140px;
    height: 135px;
  }
  .instinstitution {
    font-size: 12px;
    line-height: 20px;
  }
  .instpageHeading h1 {
    font-size: 20px !important;
  }
  .institutionuniversitiesScroll {
    height: 70%;
  }
}
@media (max-width: 992px) {
  .institutioncard1 {
    width: 125px;
    height: 110px;
  }
  .institutionNLBtnStyling button.ant-btn.ant-btn-primary.NLBtn {
    height: 55px !important;
    width: 140px !important;
    font-size: 14px !important;
  }
  .institutionpoweredText {
    font-size: 16px;
    justify-content: center;
  }
  .institutionuniversitiesScroll {
    height: 30vh;
  }
  .instpageHeading h1 {
    font-size: 16px !important;
    line-height: 33px;
  }
  span.anticon.anticon-arrow-left {
    font-size: 20px;
  }
  .institutionuniversitiesScroll {
    height: 87%;
  }
}
