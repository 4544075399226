.ant-dropdown-menu {
    background-color: black !important;
}

.ant-dropdown {
    background-color: black !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
    color: white !important;
}


.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
    background-color: #444 !important;
    color: #fff !important;
}


.ant-dropdown:hover {
    background-color: #444 !important;
}

.filterBtn {

    width: 100%;
    color: #fff !important;
    border-radius: 6px !important;
    background: linear-gradient(313deg, #37225A 0%, #1F1333 50%, #321F53 100%) !important;
    border: 1px solid rgb(62, 38, 88) !important;
}



.table-row td,
th {
    text-align: center;
}


.ant-input-affix-wrapper {
    background: #1D112C !important;
    border-radius: 10px;
    opacity: 0.6000000238418579;
}