.ant-switch {
    background-color: rgba(169, 172, 177, 0.32) !important;
}

.ant-switch-checked {
    background-color: #AD70E0 !important;
}


.ant-switch-handle::before {
    background-color: rgb(255, 255, 255) !important;
  }