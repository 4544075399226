.loginContainer {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  justify-content: center;
  background-size: cover;
  background-image: url("../../../assets//images/background.png");
  background-position-y: center;
  height: 100%;
  max-width: 100%;

}

.logoContainer {
  width: 50%;
  padding: 0 2rem;
}

.formMainContainer {
  width: 60%;
  height: 100%;
  display: flex;
  flex-direction: column;
  column-gap: 20;
  justify-content: center;
  align-items: center;
}

.welcomTextContainer {
  display: flex;
  justify-content: center;

  margin-bottom: 4rem;
}

.forgotPasswordText {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 26px;
  text-decoration: underline;
  margin-top: -1em;
}

.forgotPasswordText a {
  color: #ffffff;
  text-decoration-line: underline;

}

.loginFormContainer {
  width: 80%;
  margin-top: -3rem;
  margin-bottom: 2rem;
}

.forgotPassword {
  font-family: "Helvetica";
  color: #ffffff;
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;
  margin-top: 60px;
}

.siteLogo {
  height: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
}

.siteLogo>img {
  width: 50% !important;
}

.inputStyle {
  margin-bottom: 8px;
  font-size: large;

  .anticon svg {
    font-size: large;
  }

  .ant-input-affix-wrapper>.ant-input:not(textarea) {
    padding: 4px;
  }

}

@media screen and (min-width: 200px) and (max-width:1760px) {
  .welcomTextContainer img {
    width: 80%;

  }
}


@media screen and (min-width: 1200px) and (max-width: 1299px) {
  .loginFormContainer {

    margin-top: 2rem;
    margin-bottom: 2.4rem;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1499px) {
  .loginFormContainer {

    margin-top: 1rem;
    margin-bottom: 2.7rem;
  }
}

@media screen and (min-width: 1500px) and (max-width: 1760px) {
  .loginFormContainer {

    margin-top: 0rem;
    margin-bottom: 3rem;
  }
}

@media screen and (min-width: 1761px) and (max-width: 1899px) {
  .loginFormContainer {

    margin-top: -2rem;
    margin-bottom: 3.3rem;
  }
}