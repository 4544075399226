  .field_inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1rem;
  }

  .inputStyle-small {
    width: 100%;
    height: 30px;
    border-radius: 10px !important;
    font-family: "Montserrat";
    font-size: 14px !important;
  }

  .inputStyle-large {
    width: 100%;
    height: 48px;
    border-radius: 10px !important;
    font-family: "Montserrat";
    font-size: 14px !important;
  }

  :where(.css-dev-only-do-not-override-14wwjjs).ant-input-affix-wrapper>input.ant-input {
    font-family: "Montserrat";
    font-size: 14px;

  }

  ::-webkit-calendar-picker-indicator {
    background-image: #fff !important;
  }

  .labelStyle-large {
    margin-bottom: 1.5%;
    color: white;
    font-family: "Montserrat";
    font-size: 12px;
    text-align: left;
  }


  .labelStyle-small {
    margin-bottom: .5%;
    color: white;
    font-family: "Montserrat";
    font-size: 10px;
    text-align: left;
  }

  .field_inputContainer .ant-input {
    background: #1D112C !important;
    opacity: 0.6000000238418579;
    color: white !important;
  }

  .error_input {
    color: red !important;
  }

  .coloredInput {
    background: linear-gradient(313deg, #37225A 0%, #1F1333 50%, #321F53 100%) !important;
    color: #fff !important;
  }

  .inputBorder {
    border-radius: 10px !important;
  }