.layout-wrapper {
  background-image: url("../../../../src/assets/images/QR_Coat_Hanger_Management.jpg");
  background-repeat: no-repeat !important;
  background-size: cover;
  background-size: 100% 100%;
  min-height: 100vh !important;
  width: 100% !important;
  color: white !important;

  display: flex;
  flex-direction: row;
}

.headerStyle {
  margin-top: 1rem;
}

.layoutNavbarStyle {
  width: 100%;
  height: 20%;
}

.ant-menu.ant-menu-dark {
  background: transparent !important;
}

.ant-layout-sider-children {
  background: none;
}