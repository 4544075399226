
  .dashboard-navigation-header{
    background: linear-gradient(281.62deg, #37225a -1.26%, #1f1333 49.37%, #321f53 100%);
    height: 3rem;
    border-radius: .3rem;
    padding: 0 .9rem;
  }

  .dashboard-navigation-header-menu {
    display: flex;
    flex-direction: row;
    list-style: none;
    height: 100%;
    width: 100%;
    align-items: center;
    padding: 0px;
    white-space: nowrap;
  }

  .dashboard-navigation-active-bar{
    height: 38px;
    width: 4px;
    background-color: #AD70E0;
    border-radius: 0px 16px 16px 0px;
  }

  .dashboard-navigation-header-menu-tab {
    color: #94979e;
    display: flex;
    width: fit-content;
    height: 100%;
    align-items: center;
    transition-duration: 0.3s;
    cursor: pointer;
    gap: 1em;

    margin-right: 1rem ;
  }

  .dashboard-navigation-header-menu-tab:hover {
    color: #ad70e0 !important;
  }

  .dashboard-navigation-header-menu-tab-active {
    padding-right: 7% !important;
    color: #ad70e0;
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 2%;
  }