.profile-detail-wrapper {
    width: 100%;
}

.account-access-control {
    width: 40%;
    margin-top: 1rem;
}

.profile-detail-wrapper-img {
    max-width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin: 0 0 1rem 0;
}

.billingContainerColVenueDetails {
    display: flex;
    align-self: flex-start;
}

.billingContainerVenueDetails {
    display: flex;
    width: 419px;
    margin-left: auto;
    height: 224px;
    padding: 30px;
    flex-direction: column;
    align-self: flex-end;
    justify-content: center;
    align-items: center;
    gap: 20px;
    // border: 16px solid #1F1333;
    border-radius: 16px;
    background: var(--Button-Gradient, linear-gradient(282deg, #37225A -1.26%, #1F1333 49.37%, #321F53 100%));
    box-shadow: 3px 4px 4px 0px rgba(255, 249, 249, 0.25) inset, 3px 4px 8px 0px rgba(243, 233, 251, 0.25);
}

.billingTitleVenueDetails {
    color: #FFF;
    font-family: Norwester;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;

    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    align-self: stretch;
}

.billingSubscriptionContainerVenueDetails {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: stretch;
}

.billingSubscriptionPriceVenueDetails {
    color: var(--Text-White-Desr, #FFFBF5);
    text-align: right;

    /* Display medium 45/ bold */
    font-family: Montserrat;
    font-size: 45px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    /* 115.556% */
}

.billingSubscriptionPrice2VenueDetails {
    color: var(--Text-White-Desr, #FFFBF5);
    text-align: right;

    /* Headline medium 28/ semibold */
    font-family: Montserrat;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
    /* 128.571% */


}

.billingSubscriptionDateVenueDetails {
    color: var(--Paragragh-white, #FFF);
    text-align: right;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 125% */
    /////////////////
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    align-self: stretch;
}

.profile-detail-wrapper {
    margin: 1rem 0;
}

.profile-detail {
    max-width: 100%;
}

.profile-detail-row {
    display: flex;
    justify-content: space-between;
    margin: 0 0 .5rem 0;
}

.profile-detail-labeled {
    font-weight: 600;
    font-size: 20px;
}

.noDataText {
    color: gray;
    font-weight: 500;
}

.profile-detail-label {
    display: flex;
    gap: 5px;
}

.right-menu {
    display: flex;
    justify-content: end;
}

.form-wrapper {
    margin: 1rem 0;
}

.left-menu {
    margin-bottom: 1rem;
}

@media screen and (min-width: 1200px) and (max-width: 1444px) {
    .giveAccessToCustomer {
        font-size: 12px;
    }
}

.ant-input::placeholder {
    color: #94979E !important;

}