.navbar {
    padding: 3% 2%;
    height: 7vh;
    width: 95%;
}

.navbar-logo {
    height: 65px;
}

.navbar-menu {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: center;
}

.searchBarIcon {
    height: 22px;
}

.navbar-search-bar {
    width: 25%;
    background: transparent !important;
}

.ant-input-affix-wrapper,
.ant-input-affix-wrapper input {
    // background: #1d112c !important;
    // color: white;
    border-radius: 6px !important;
    border: 0px !important;

    background: 'transparent';

}

.navbar-links {
    width: 75%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.navbar-links>* {
    margin: 0% 1%;
}

.navbar-links-un {
    color: white;
    background: #5d3a96;
    padding: 14px 13px;
    border-radius: 22px;
}

.navbar-links-userName {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0% 5%;
}

.navbar-links-username-firstName {
    color: white;
    font-size: 18px;
}

.navbar-links-username-lastName {
    color: white;
    font-size: 18px;
    font-weight: 900;
    margin-left: 4%;
}

.navbar-links-username-divider {
    border: 1px solid white;
    height: 20px;
}

.navbar-links-user-data {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar-links-user-type {
    color: white;
    font-size: 13px;
    margin-left: 3%;
}

.navbar-links-drop-down-icon {
    background-color: transparent;
    box-shadow: none;
    border: none;
    cursor: pointer;
}