.ant-spin-dot {
    i {
        background-color: white;
    }
}

.spinLayout {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;

}