.table-wrapper {
  max-width: 100%;
}

.table-pagination-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}

.table-paginate {
  background: linear-gradient(313deg, #37225a 0%, #1f1333 50%, #321f53 100%);
  padding: 1rem 1rem;
  border-radius: 10px;
}

.table-body {
  min-width: 100vh;
  height: 100%;
  // overflow-y: auto;
}

.table-tag {
  width: 100%;
  height: 100%;
}

/* Styling for table headers */
.table-heading {
  font-weight: bold;
  background: var(--button-gradient,
      linear-gradient(313deg, #37225a 0%, #1f1333 50%, #321f53 100%));
  color: white;
  padding: 15px 17px;
  text-align: center;
  border-bottom: 1px solid #a1a1a1;
  white-space: nowrap;
}

/* Styling for table cells (except headers) */
.table-data {
  padding: 5px;
  color: white;
  text-align: left;
  padding: 15px 17px;
  border-bottom: 1px solid #a1a1a1;
  background: var(--button-gradient,
      linear-gradient(313deg, #37225a 0%, #1f1333 50%, #321f53 100%));
  text-align: center;
}

td>a {
  display: flex;
  justify-content: center;
}

.sorting-icon {
  margin-left: 11%;
  cursor: pointer;
}

.action-button {
  position: relative;
  left: 37%;
  cursor: pointer;
}

.action-menu {
  display: none;
  position: absolute;
  border-radius: 4px;
  padding: 0px;
  margin: 0px;
  background-color: black;
  box-shadow: 0px 4px 7px 8px rgba(16, 24, 40, 0.08);
}

.action-menu li {
  cursor: pointer;
  padding: 15px 13px;
  color: white;
  list-style: none;
  background-color: transparent;
  transition-duration: 0.2s;
}

.action-menu li:hover {
  background-color: #0e0e0e;
}

.action-menu li img {
  position: relative;
  top: 3px;
  margin-right: 7px;
}

// .button-col-style {
//     // width: 30%;
//         display: flex;
// }
.action-menu li img {
  position: relative;
  top: 3px;
  margin-right: 7px;
}

.ant-pagination-total-text {
  color: #fff !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  background: none !important;
  color: #FFF !important;
  border-color: linear-gradient(313deg, #37225A 0%, #1F1333 50%, #321F53 100%) !important;
  border: none !important;
}

// .ant-pagination {
//   color: #fff !important;
// }

.ant-pagination-item-active,
.ant-pagination-item {
  background: none !important;
  border: none !important;
}


.ant-pagination-item a {
  color: #fff !important;
}

.ant-pagination-item-active a {
  color: #fff !important;
  border: 1px solid #5D3A96;
  border-radius: 50% !important;
  background: #5D3A96 !important;
}

.ant-pagination-disabled .ant-pagination-item-link {
  color: #94979E !important;
}

.ant-pagination-jump-next .ant-pagination-jump-next-custom-icon:hover {
  color: #fff !important;
}

.ant-pagination-item-container>span>svg {
  fill: #fff !important;
}

.ant-select-selection-item {
  color: #fff !important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: #fff !important;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis:hover {
  color: #fff !important;
}

@media screen and (min-width: 1200px) and (max-width: 1444px) {
  .table-tag {

    font-size: 10px;
  }
}

@media screen and (min-width: 1142px) and (max-width: 1199px) {
  .table-tag {

    font-size: 9px;
  }
}

@media screen and (min-width: 1042px) and (max-width: 1141px) {
  .table-tag {

    font-size: 8px;
  }
}