.side-bar-menu {
  height: 100%;
  width: 100%;
}

.side-bar-menu-list {
  list-style: none;
  height: 100%;
  padding-left: 8%;
  padding-top: 5%;
}

.side-bar-menu-tab {
  display: flex;
  height: 10% !important;
  flex-direction: row;
  align-items: center;
  width: 60% !important;
  padding: 0px 15px;
  cursor: pointer;
  background: none;
  transition-duration: 0.3s;
  border-radius: 10px;
}

.side-bar-menu-tab:hover {
  background: url("../../../assets/images/sidebarMenueBG.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.side-bar-menu-tab-link {
  text-decoration: none;
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  width: 100%;
}

.side-bar-menue-tab-link-label {
  margin-left: 4%;
}

.side-bar-menue-tab-link-icon {
  height: 22px;
}

.side-bar-menu-tab-active {
  display: flex;
  height: 20%;
  flex-direction: row;
  align-items: center;
  width: 86%;
  padding: 0px 15px;
  cursor: pointer;
  border-radius: 10px;
  background: url("../../../assets/images/sidebarMenueBG.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected {
  // background-color: rgb(159, 27, 159, 0.5) !important;
  background: url("../../../assets/images/sidebarMenueBG.png") !important;
  background-size: 100% 2.8rem !important;
  color: #fff;
  // height: 20% !important;
  font-weight: bold !important;
  // padding: .3rem;
}

.sideBarlogoContainer {
  width: 80%;
  padding: 2rem 1rem;
}

.ant-menu>li {
  text-align: left !important;
  color: white !important;
}