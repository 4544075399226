  .select_inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 1.3rem;
  }

  .labelStyle_large {
    margin-top: 2%;
    margin-bottom: 1.5%;
    color: white;
    font-family: "Montserrat";
    font-size: 12px;
    text-align: left;
  }

  .labelStyle_small {
    margin-top: 2%;
    margin-bottom: .5%;
    color: white;
    font-family: "Montserrat";
    font-size: 10px;
    text-align: left;
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: none !important;
    border-radius: 10px !important;
    background: #1D112C !important;
    opacity: 0.6000000238418579;
    color: #94979E !important;
  }

  .ant-select-arrow {
    color: grey !important;
  }

  .custom-arrow .ant-select-arrow svg {
    width: 10px;
    height: 10px;
  }

  .ant-pagination-options-size-changer {
    font-size: 8px;
    /* Adjust the font size to your preference */
    padding: 4px 8px;
    /* Adjust the padding to your preference */
    height: auto;
    /* Adjust the height to your preference */
  }

  .selectInput_small {
    width: 100%;
    height: 30px;
    border-radius: 10px !important;
    font-family: "Montserrat";
    font-size: 14px !important;
  }

  .selectInput_large {
    width: 100%;
    height: 48px;
    border-radius: 10px !important;
    font-family: "Montserrat";
    font-size: 14px !important;
  }