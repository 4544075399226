.search_container {
    .ant-input-affix-wrapper {
        background: #1D112C !important;
        border: none;
        color: white !important;
        height: 45px !important;
    }

    .ant-input-affix-wrapper>input {
        background: none !important;
    }

    .ant-input {
        padding: 0 1rem !important;
        width: 100% !important;
        color: white;
        background: none !important;
    }

    .searchInput {

        opacity: 0.6000000238418579;
    }


}