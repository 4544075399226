.staff-form-style {
    // margin-left: 6.5rem;
    margin-top: 1rem;
}

.staff-account-form {
    height: 100%;
    width: 60%;
}

.staff-page-header-style {
    // margin-right: 10rem;
}

.ant-checkbox-wrapper {
    color: white !important;
}

.ant-input-item {
    margin-bottom: 0px !important;
}

.staff-feature-checkbox-heading {
    color: white;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 15px;
}

/* Custom checkbox styles */
.custom-checkbox input[type='checkbox'] {
    opacity: 0;
    /* Make the checkbox transparent */
}

.custom-checkbox .ant-checkbox-inner {
    border: 2px solid white;

    background-color: transparent;

    border-color: white !important;
    outline: 0 !important;
    -webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, .2) !important;
    box-shadow: 0 0 0 2px rgba(87, 168, 233, .2) !important;
}




.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
    outline: thin dotted !important;
    // outline: 5px auto red !important;
    outline-offset: -2px !important;
}

.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
    border-color: white;
    background-color: transparent;


}

.neverLeftStaffAccountTitle {
    font-family: 'Montserrat';
    font-size: 32px;
    font-weight: 300;
    line-height: 48px;
    letter-spacing: 0px;
    text-align: center;
    color: white;
}

@media screen and (min-width: 1200px) and (max-width: 1444px) {
    .neverLeftStaffAccountTitle {
        font-size: 12px;
    }
}