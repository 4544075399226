.inputContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
}

.inputStyles {
  width: 100%;
  height: 48px;
  border-radius: 10px !important;
  font-family: 'Montserrat';
  font-size: 14px !important;
  background-color: rgb(255, 255, 255);
  color: #94979e;
  margin-bottom: 10px;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-input-affix-wrapper>input.ant-input {
  font-family: "Montserrat";
  font-size: 14px;
}

.inputStyle>input.ant-input {
  background-color: rgba(255, 255, 255, 1) !important;
  opacity: 1 !important;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // width: 100%;
}

.inputContainer>.ant-input-affix-wrapper {
  background-color: rgba(255, 255, 255, 1) !important;
  opacity: 1 !important;
}

.labelStyle {
  color: white;
  font-family: "Montserrat";
  font-size: 14px;
  text-align: left;
  margin-bottom: 0.3rem;
  margin-top: -1rem;
}