.buttonContainer {
  width: 100%;
  // margin-top: 1rem;
    // height: 30px;
}

.buttonContainer > button > span {
  font-family: 'Montserrat', sans-serif !important;
  line-height: 18px;
  font-size: 14px;
}

.buttonStyle {
  width: 100%;
  height: 48px !important;
  border-radius: 8px !important;
  background-image: url("../../../assets/images/buttonBackground.png") !important;
  background-repeat: no-repeat !important;
  background-size: 102% 102% !important;
  font-family: "Montserrat Bold";
  color: white !important;
  font-size: 20px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: 18px !important;
  border: 0px solid transparent !important;
}

.disableButtonStyle {
  width: 100%;
  color: white !important;
  height: 43px;
  border-radius: 4px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  font-family: "Montserrat Bold";
  background-color: #c6c3c3 !important;
  font-size: 20px;
}
