.ant-picker {
    height: 30px !important;
    border: none !important;
    border-radius: 10px !important;
    width: 100%;
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 18px !important;
    background: #1D112C !important;
    opacity: 0.6000000238418579;
    color: #94979E !important;
}

.ant-picker-input > input {
    color: white !important;
}

.ant-picker-suffix {
    display: flex;
    flex: none;
    align-self: center;
    margin-left: 4px;
    color: #94979E !important;
    line-height: 1;
    pointer-events: none;
}

.ant-picker-today-btn {
    color: #1D112C !important;
    font-family: "Montserrat" !important;
    font-style: normal !important;
    font-weight: 900 !important;
    font-size: 16px !important;
    line-height: 18px !important;
}

.ant-picker-input input::placeholder {
    color: #94979E !important;
    /* Change the color here to the desired color */
}